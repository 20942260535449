import React from 'react'
import Layout from '../layout/layout'

const thankyou = () => (
  <Layout cssClass="page-404">
    <div id="main-content">
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-8 mx-auto text-center">
              <h1>Thank you for your enquiry</h1>
              <p>We will try to get back to you as soon as possible.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default thankyou
